// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-club-night-tsx": () => import("/opt/build/repo/src/templates/clubNight.tsx" /* webpackChunkName: "component---src-templates-club-night-tsx" */),
  "component---src-templates-artist-tsx": () => import("/opt/build/repo/src/templates/artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */),
  "component---src-templates-release-tsx": () => import("/opt/build/repo/src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clubnights-tsx": () => import("/opt/build/repo/src/pages/clubnights.tsx" /* webpackChunkName: "component---src-pages-clubnights-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-radioshows-tsx": () => import("/opt/build/repo/src/pages/radioshows.tsx" /* webpackChunkName: "component---src-pages-radioshows-tsx" */)
}

